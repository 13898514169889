.box {
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    padding: 75px 10px 60px;
    margin-top: 24px;
    height: 400px;
    border-radius: 10px;
    background-color: #fff;
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
}

.box:hover {
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    transform: scale(1.05);
    background-color: #6C4D38; /* Color change on hover */
}

.heading {
    font-size: 24px;
    color: #222a41;
    font-weight: bold;
    margin-top: 20px;
    text-align: center;
}

.text {
    font-size: 18px;
    color: #6d7783;
    margin: 0 auto;
    text-align: center;
    max-width: 90%;
}

.form-container {
    margin-top: 30px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.form-container input, .form-container select, .form-container textarea {
    width: 100%;
    padding: 12px;
    margin: 8px 0;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 16px;
}

.form-container button {
    background-color: #6c4d38;
    color: white;
    padding: 12px 25px;
    font-size: 18px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.form-container button:hover {
    background-color: #080605;
}
