.parentContainer {
    padding: 40px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 1200px;
    margin: 40px auto;
}

.textContainer {
    margin-bottom: 40px;
    text-align: center;
}

.heading {
    font-size: 30px;
    font-weight: 500;
    color: #6C4D38 !important;
    font-family: 'Poppins';
    margin-bottom: 10px;
}

.subHeading {
    font-size: 34px;
    font-family: 'Poppins';
    font-weight: bold;
    margin-bottom: 25px;
}

.content {
    font-size: 16px;
    font-family: 'Poppins';
    color: #6d7783;
    line-height: 1.6;
    text-align: justify;
}

.countersContainer {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
}

.counters {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
}

.counter {
    text-align: center;
    margin: 10px;
    flex: 1 1 150px; /* Adjusts size dynamically */
}

.icon {
    color: #6C4D38;
    margin-bottom: 10px;
}

.counterValue {
    font-size: 24px;
    font-weight: bold;
    color: #6C4D38;
}

.counterLabel {
    font-size: 14px;
    color: #6d7783;
}
