.heading {
    font-family: 'Poppins';
    font-size: 30px;
    font-weight: 500;
    color: #ff4c0a;
    margin-top: 77px;
    margin-bottom: 0px;
}
.subHeading {
    font-family: 'Poppins';
    font-size: 34px;
    font-weight: bold;
    color: rgb(108, 77, 56);
    margin-top: 36px;
}
.content {
    font-family: 'Poppins';
    font-size: 14px;
    color: #000000;
    margin-top: 14px;
    width: 700px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 70px;
}
.section {
    background-color: #f8f8f8; /* Light background color for contrast */
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 40px; /* Space between sections */
}
.about {
    font-family: 'Poppins';
    font-size: 50px;
    font-weight: bold;
    color: rgb(108, 77, 56);
    text-align: left;
    margin-bottom: 4px;
    margin-top: 20px; /* Adjusted for spacing */
}
.creativity {
    font-family: 'Poppins';
    font-size: 30px;
    font-weight: bold;
    color: rgb(108, 77, 56);
    text-align: left;
}
.contn {
    font-family: 'Poppins';
    font-size: 14px;
    color: #989e9d;
    text-align: left;
}
.textHighlight {
    font-family: 'Poppins';
    font-size: 24px;
    font-weight: bold;
    color: rgb(108, 77, 56);
    text-align: center; /* Center text for discovery section */
    margin-bottom: 10px; /* Space between lines */
}

/* Responsive Styles */
@media screen and (max-width: 1200px) {
    .section {
        padding: 15px;
    }
}
@media screen and (max-width: 992px) {
    .heading {
        font-size: 25px;
        margin-top: 60px;
    }
    .subHeading {
        font-size: 29px;
        margin-top: 30px;
    }
    .about {
        font-size: 40px;
    }
}
@media screen and (max-width: 768px) {
    .content {
        width: 100%;
    }
}
@media screen and (max-width: 650px) {
    .heading {
        font-size: 20px;
        margin-top: 40px;
    }
    .subHeading {
        font-size: 24px;
        margin-top: 20px;
    }
    .about {
        font-size: 30px;
        margin-top: 60px;
    }
}
