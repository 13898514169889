.company-team-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
}

.team-header {
    margin-bottom: 40px;
}

.team-header h1 {
    font-size: 36px;
    color: #333;
    margin-bottom: 10px;
}

.team-header p {
    font-size: 18px;
    color: #555;
}

.team-grid {
    display: flex; /* Changed from grid to flex for centering */
    justify-content: center; /* Center the team cards */
    gap: 20px;
    margin-top: 20px;
}

.team-card {
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 350px; /* Fixed width for consistent card size */
}

.team-card:hover {
    transform: translateY(-10px);
}

.team-image-container {
    width: 100px; /* Adjust width as needed */
    height: 100px; /* Adjust height as needed */
    overflow: hidden; /* Ensures overflow is hidden */
    border-radius: 50%; /* Fully rounded container */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}

.team-image {
    width: 100%; /* Full width of the container */
    height: auto; /* Maintains aspect ratio */
    object-fit: cover; /* Ensures image covers the container */
}

h3 {
    font-size: 20px;
    color: #333;
    margin-bottom: 10px;
}

.team-role {
    font-size: 14px;
    color: #777;
    margin-bottom: 5px;
}

.team-expertise {
    font-size: 13px;
    color: #999;
    margin-bottom: 10px;
}

.team-bio {
    font-size: 13px;
    color: #555;
}

.social-links {
    display: flex;
    gap: 15px;
    margin-top: 10px;
}

.social-links a {
    color: #555;
    font-size: 24px;
    transition: color 0.3s ease, transform 0.3s ease;
}

.social-links a:hover {
    color: #0077b5;
    transform: scale(1.1);
}

/* Responsive Styles */
@media (max-width: 768px) {
    .team-grid {
        flex-direction: column; /* Stack members vertically on smaller screens */
        align-items: center; /* Center cards */
    }

    .team-header h1 {
        font-size: 28px;
    }

    .team-header p {
        font-size: 16px;
    }

    .team-card {
        padding: 15px;
    }

    h3 {
        font-size: 18px;
    }

    .team-role,
    .team-expertise,
    .team-bio {
        font-size: 12px;
    }

    .team-image-container {
        width: 80px;
        height: 80px;
    }
}

@media (max-width: 480px) {
    .team-grid {
        grid-template-columns: 1fr;
    }

    .team-header h1 {
        font-size: 24px;
    }

    .team-header p {
        font-size: 14px;
    }

    h3 {
        font-size: 16px;
    }

    .team-role,
    .team-expertise,
    .team-bio {
        font-size: 12px;
    }

    .team-image-container {
        width: 60px;
        height: 60px;
    }
}
