.contain {
    padding: 20px 0;
    margin-bottom: 150px;
}

.head {
    font-family: 'Poppins';
    font-size: 30px;
    font-weight: 500;
    color: #6C4D38;
    margin-bottom: 13px;
}

.our {
    display: inline;
    font-size: 34px;
    font-weight: bold;
    color: #000;
}

.study {
    display: inline;
    font-size: 34px;
    font-weight: bold;
    color: #6C4D38;
}

.grp_btn {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
}

.filter {
    cursor: pointer;
    padding: 10px;
    font-size: 16px;
    font-family: 'Poppins';
    color: #333;
    font-weight: 500;
}

.filter:hover {
    color: #e0a57d;
    font-weight: bold;
}

.active {
    font-weight: bold;
    color: #6C4D38; 
}

.img {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 250px;
    border-radius: 8px;
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    transition: transform 0.3s;
}

.img:hover {
    transform: scale(1.05);
}

.imgTitle {
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    margin-top: 10px;
    text-align: center;
}

/* Media Queries for responsiveness */
@media screen and (max-width: 992px) {
    .head {
        font-size: 25px;
    }
    .our, .study {
        font-size: 30px;
    }
    .filter {
        font-size: 18px;
    }
    .grp_btn {
        margin: 30px 0;
    }
}

@media screen and (max-width: 768px) {
    .head {
        font-size: 18px;
    }
    .our, .study {
        font-size: 22px;
    }
    .filter {
        font-size: 14px;
    }
    .grp_btn {
        margin: 25px 0;
    }
}
