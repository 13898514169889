.contain{
    margin-top: 130px;
    margin-bottom: 130px;
    padding-left: 60px !important;
    padding-right: 60px !important;
}

.heading{
    font-size: 30px;
    color: #6C4D38 !important;
    font-weight: 500;
}
.subHeading{
    font-size: 34px;
    font-weight: bold;
    color: #222a41;
    width: 645px;
}
.icon {
    width: 60px; /* Adjust size as needed */
    height: 60px;
    margin-bottom: 15px; /* Space below the icon */
}
.serviceCard {
    text-align: center; /* Center text and icon */
    padding: 20px; /* Add padding for spacing */
    border: 1px solid #ddd; /* Optional: border for cards */
    border-radius: 5px; /* Optional: rounded corners */
    margin-top: 10px;
}

.link {
    color: #6C4D38 !important; /* Set the color */
    text-decoration: none; /* Remove underline */
    transition: color 0.3s; /* Optional: smooth color transition */
}

.link:hover {
    color: #94725b !important; /* Optional: change color on hover */
}

@media screen and (max-width: 1200px) {
    .contain{
        margin-top: 80px;
        margin-bottom: 80px;
        padding-left: 40px !important;
        padding-right: 40px !important;
    }
}

@media screen and (max-width: 770px) {
    .subHeading{
        font-size: 25px;
        width: auto;
    }
    .heading{
        font-size: 20px;
    }
}

@media screen and (max-width: 430px) {
    .subHeading{
        font-size: 20px;
    }
    .heading{
        font-size: 17px;
    }
    .contain{
        margin-top: 80px;
        margin-bottom: 100px;
    }
}
@media screen and (max-width: 380px) {
    .contain{
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
}
