.our-shop-container {
  width: 90%;
  margin: 0 auto;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.shop-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.cart-icon {
  position: relative;
  cursor: pointer;
}

.cart-count {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 5px;
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
}

.product-card {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  width: 400px;
  height: 400px;
}

.product-card img {
  width: 200px; 
  height: 180px;
  object-fit: cover;
  margin-bottom: 10px;
}

.add-to-cart-btn {
  background-color: #b8860b; /* Golden brown */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.add-to-cart-btn:hover {
  background-color: #a57d00; /* Darker golden brown */
}

.cart-dropdown {
  position: absolute;
  right: 20px;
  top: 60px;
  width: 300px;
  border: 1px solid #ddd;
  background-color: white;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 10px;
  margin-top: 125px;
}

.cart-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.cart-item-img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.cart-item-details {
  flex-grow: 1;
}

.cart-item-controls {
  display: flex;
  align-items: center;
}

.quantity-btn {
  background-color: #b8860b;
  color: white;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
  margin: 0 5px;
}

.quantity-btn:hover {
  background-color: #a57d00;
}

.remove-item-btn {
  background-color: red;
  color: white;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
  margin-left: 10px;
}

.checkout-btn {
  background-color: #b8860b;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
}

.checkout-btn:hover {
  background-color: #a57d00;
}

.cart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.close-cart-btn {
  cursor: pointer;
}

.cart-dropdown {
  position: absolute;
  right: 20px;
  top: 60px;
  width: 300px;
  border: 1px solid #ddd;
  background-color: white;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 10px;
  z-index: 100; /* Ensure it appears above other content */
}

.search-bar {
  display: flex;
  align-items: center;
  margin-right: 20px;
  justify-content: center; /* Center align on smaller screens */
}

.search-bar input {
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  width: 500px;
  border: 1px solid #ccc;
  border-radius: 15px;
  margin-right: 8px;
  max-width: 100%; /* Ensures it doesn't overflow on smaller screens */
}

.search-icon {
  cursor: pointer;
}

/* New styles for sorting */
.sidebar {
  margin: 20px 0; /* Space above and below the sidebar */
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 8px;
}

.sort-container {
  position: relative;
}

.sort-option-list {
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 10;
  padding: 10px;
  margin-top: 5px;
}

.sort-option {
  padding: 5px 10px;
  cursor: pointer;
}

.sort-option:hover {
  background: #f0f0f0;
}




/* Responsive adjustments */
@media (max-width: 1024px) {
  .search-bar input {
    width: 350px; /* Reduce width on tablet screens */
  }
  .product-grid {
    grid-template-columns: repeat(3, 1fr); /* 3 columns on tablets */
  }
}

@media (max-width: 768px) {
  .search-bar input {
    width: 100%; /* Full width on mobile screens */
  }
  .product-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on mobile */
  }

  .cart-dropdown {
    width: 100%; /* Full width on mobile */
    left: 0;
    right: 0;
    top: 60px;
  }

  .checkout-btn {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .shop-header {
    flex-direction: column;
  }

  .cart-icon {
    margin-top: 10px;
  }

  .product-card {
    padding: 10px; /* Reduced padding for mobile */
  }

  .add-to-cart-btn {
    padding: 8px 15px; /* Adjust button size for smaller screens */
  }

  .cart-dropdown {
    padding: 15px;
  }
}
