.aboutContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    margin-bottom: 100px;
    padding: 40px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    transition: transform 0.3s ease, background-color 0.3s ease;
}

.aboutContainer:hover {
    transform: scale(1.02);
    background-color: #f3e6d9;
}

.textBox {
    max-width: 800px;
    text-align: left;
}

.head {
    font-size: 28px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    color: #6c4d38;
    margin-bottom: 20px;
}

.heading {
    font-size: 36px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    color: #6c4d38;
    margin-bottom: 20px;
}

.content {
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    color: #444;
    line-height: 1.8;
    margin-bottom: 30px;
}

.buttonContainer {
    text-align: center;
}

.button {
    background-color: #6c4d38;
    color: #fff;
    border: none;
    padding: 15px 30px;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.button:hover {
    background-color: #9e785f;
}
