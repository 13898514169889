.forgotPasswordContainer {
    max-width: 400px;
    margin: auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 30px;
    margin-bottom: 50px;
  }
  
  .title {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333; /* Use the specified color */
  }
  
  .description {
    margin-bottom: 20px;
    color: #333;
  }
  
  .form {
    display: flex;
    flex-direction: column;
  }
  
  .inputField {
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%; /* Ensure full width */
  }
  
  .submitButton {
    background-color: #6C4D38; /* Use the specified color */
    color: white;
    border: none;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .backLink {
    margin-top: 20px;
    text-align: center;
  }
  
  .link {
    color: #6C4D38; /* Use the specified color */
    text-decoration: none;
  }
  .modalContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.successIcon {
    color: #28a745; /* Green color for success */
    font-size: 40px;
    margin-bottom: 10px;
}

.errorIcon {
    color: #dc3545; /* Red color for error */
    font-size: 40px;
    margin-bottom: 10px;
}

.modal {
    max-width: 400px;
    margin: auto;
    padding: 20px;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
